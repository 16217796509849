@import url('../../styles/customMediaQueries.css');

.container {
  position: relative;
  padding: 0 100px;
  margin-top: 36px;
  @media (--viewportMedium) and (--viewportExtraMaxlarge) {
    padding: 0 40px;
  }
  @media (--viewportMaxMedium) {
    padding: 0 20px;
  }
}
h1 {
  font-family: var(--fontFamilyHelmet);
  font-size: 40px;
  font-weight: 400;
  line-height: 44.38px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  margin: 0;
  padding: 0;
  @media (--viewportMaxMedium) {
    font-size: 24px;
    line-height: normal;
  }
}
p {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubHeading);
  font-weight: 400;
  line-height: 24.41px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  margin: 0;
  padding: 0;
  margin: 20px 0;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
    line-height: normal;
    margin: 14px 0;
  }
}

.privacyPolicyList{
  margin-block: 20px;
}
.privacyPolicyWrapper .privacyPolicyPoints{
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubHeading);
  font-weight: 400;
  line-height: 24.41px;
  letter-spacing: var(--letterSpacingDescription);
  margin: 20px 0;
}
.privacyPolicyWrapper .privacyPolicyPoints{
  position: relative;
  padding-left: 40px;
}
.privacyPolicyWrapper .privacyPolicyPoints::before{
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  top: 12px;
  left: 16px;
}

@media (max-width:768px){
  .privacyPolicyWrapper .privacyPolicyPoints{
    font-size: var(--fontSizeDescription);
    line-height: normal;
    margin: 14px 0;
  }
}