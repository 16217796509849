@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  width: 100%;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;

  @media (--viewportMaxMedium) {
    margin-bottom: 60px;
  }
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLink span {
  text-decoration: underline;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}