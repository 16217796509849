@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.finePrint {
  padding: 3px 0 5px 0;
  text-align: left;
   font-size: var(--fontSizeSubText);
}

.privacyLink,
.termsLink {
  color: var(--marketplaceColor);
  font-size: var(--fontSizeSubText) !important;

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}

.root input {
  width: 20px !important;
}